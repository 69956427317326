/**
 * 数据分析-楼盘信息统计-详情列表-查看
 * luxinwen
 * 2023-04-26
 */
<template>
  <div>
    <Drawer :title="title" v-model="myValue" width="600" :before-close="beforeClose">
      <div class="page-main-content" v-grant="'OSP_ANALYSIS_PROJECT_EXPORT'">
        <Button type="primary" @click="exportData">导出</Button>
        <Button @click="showRecord">查看导出记录</Button>
      </div>
      <sp-table :columns="shareColumns" :data="formData.list" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage" v-if="flag === 'share'"></sp-table>
      <sp-table :columns="visitColumns" :data="formData.list" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage" v-if="flag === 'visit'"></sp-table>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>

    <Modal v-model="recordDisplay" width="80%" title="导出记录" footer-hide :mask-closable="false">
      <sp-table :columns="recordColumns" :data="recordDataList" :loading="recordLoading" :total="recordPager.total" :page="recordPager.pageNum" :page-size="recordPager.pageSize" @change="changeRecordPage">
      </sp-table>
    </Modal>
  </div>
</template>

<script>
  const formData = {
    list: []
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        backupData: {},
        shareColumns: [
          {
            title: '日期',
            key: 'shareTime'
          },
          {
            title: '分享次数',
            key: 'shareCount'
          },
          {
            title: '被查看总次数',
            key: 'lookCount'
          }
        ],
        visitColumns: [
          {
            title: '查看人',
            key: 'memberName'
          },
          {
            title: '电话',
            key: 'phone'
          },
          {
            title: '日期',
            key: 'lookTime'
          }
        ],
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        recordDisplay: false,
        recordColumns: [
          {
            type: 'index',
            title: '序号',
            width: 100,
            align: 'center'
          },
          {
            title: '文件名',
            key: 'fileName'
          },
          {
            title: '操作人',
            key: 'operator'
          },
          {
            title: '导出时间',
            key: 'createdTime'
          },
          {
            title: '操作',
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  attrs: {
                    href: params.row.downloadUrl,
                    target: '_blank'
                  }
                }, '请下载')
              ]);
            }
          }
        ],
        recordDataList: [],
        recordLoading: false,
        recordPager: {
          total: 0,
          pageNum: 1,
          pageSize: 10
        }
      };
    },
    computed: {
      title() {
        return {
          'share': '分享总次数',
          'visit': '被查看总人次'
        }[this.flag];
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.pager.pageNum = 1;
        this.initData(this.data);
        let url = this.$api.analysis.queryShareStatictics;
        if (this.flag === 'visit') {
          url = this.$api.analysis.queryShareStaticticsUser;
        }
        this.$axios({
          url,
          data: {
            id: this.data.id,
            memberId: this.data.memberId,
            pageNum: this.pager.pageNum,
            pageSize: this.pager.pageSize
          },
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          let _data = Object.assign({}, this.data, {
            list: data.list || []
          });
          this.pager.total = data.total || 0;
          this.initData(_data, true);
        });
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 导出
       */
      exportData() {
        let url = this.$api.analysis.exportShareStatictics;
        if (this.flag === 'visit') {
          url = this.$api.analysis.exportShareStaticticsUser;
        }
        this.$axios({
          url,
          data: {
            id: this.data.id,
            memberId: this.data.memberId
          },
          loading: true
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '操作成功，请稍后查看导出记录'
          });
        });
      },
      /**
       * 查看导出记录
       */
      showRecord() {
        this.recordDisplay = true;
        this.getRecordData();
      },
      /**
       * 获取导出记录数据
       */
      getRecordData() {
        let params = {
          id: this.dataId,
          operationType: this.flag === 'visit' ? 'FXBCKLB' : 'JJRFXLB',
          pageNum: this.recordPager.pageNum,
          pageSize: this.recordPager.pageSize
        };
        this.$axios({
          url: this.$api.analysis.exportRecordList,
          data: params,
          beforeSend: () => {
            this.recordLoading = true;
          },
          complete: () => {
            this.recordLoading = false;
          }
        }).then(data => {
          this.recordDataList = data.list || [];
          this.recordPager.total = data.total || 0;
        });
      },
      /**
       * 切换页码或每条页数
       */
      changeRecordPage(pager) {
        this.recordPager.pageNum = pager.page;
        this.recordPager.pageSize = pager.pageSize;
        this.getRecordData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>