/**
 * 数据分析-楼盘信息统计-详情列表
 * luxinwen
 * 2023-04-26
 */
<template>
  <div class="page">
    <div class="page-main">
      <!-- <div class="top-form" v-grant="'OSP_ANALYSIS_PROJECT_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.buildingNo" placeholder="请输入项目ID" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.name" placeholder="请输入项目名称" clearable />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div> -->
      <div class="page-main-content" v-grant="'OSP_ANALYSIS_PROJECT_EXPORT'">
        <Button type="primary" @click="exportData">导出</Button>
        <Button @click="showRecord">查看导出记录</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="tableColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="shareNum">
            <span class="link" @click="showDetail(params.row, 'share')">{{ params.row.shareCount }}</span>
          </template>
          <template slot-scope="params" slot="visitNum">
            <span class="link" @click="showDetail(params.row, 'visit')">{{ params.row.byShareCount }}</span>
          </template>
        </sp-table>
      </div>
    </div>

    <Modal v-model="recordDisplay" width="80%" title="导出记录" footer-hide :mask-closable="false">
      <sp-table :columns="recordColumns" :data="recordDataList" :loading="recordLoading" :total="recordPager.total" :page="recordPager.pageNum" :page-size="recordPager.pageSize" @change="changeRecordPage">
      </sp-table>
    </Modal>
    <detail v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag"></detail>
  </div>
</template>

<script>
  import detail from './detail-table.vue';

  export default {
    components: {
      detail
    },
    data() {
      return {
        formData: {
          buildingNo: '', // 项目ID
          name: '' // 项目名称
        },
        tableColumns: [
          {
            title: '项目ID',
            key: 'buildingNo'
          },
          {
            title: '项目名称',
            key: 'name'
          },
          {
            title: '经纪人',
            key: 'memberName'
          },
          {
            title: '分享总次数',
            slot: 'shareNum'
          },
          {
            title: '被查看总人次',
            slot: 'visitNum'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'share',  // 编辑抽屉状态
        dataId: '',
        recordDisplay: false,
        recordColumns: [
          {
            type: 'index',
            title: '序号',
            width: 100,
            align: 'center'
          },
          {
            title: '文件名',
            key: 'fileName'
          },
          {
            title: '操作人',
            key: 'operator'
          },
          {
            title: '导出时间',
            key: 'createdTime'
          },
          {
            title: '操作',
            align: 'center',
            render: (h, params) => {
              return h('div', [
                h('a', {
                  attrs: {
                    href: params.row.downloadUrl,
                    target: '_blank'
                  }
                }, '请下载')
              ]);
            }
          }
        ],
        recordDataList: [],
        recordLoading: false,
        recordPager: {
          total: 0,
          pageNum: 1,
          pageSize: 10
        }
      };
    },
    created() {
      this.dataId = this.$route.query.id;
      if (this.dataId) this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          id: this.dataId,
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.analysis.queryBuildingInfoStaticticsUser,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 查看导出记录
       */
      showRecord() {
        this.recordDisplay = true;
        this.getRecordData();
      },
      /**
       * 获取导出记录数据
       */
      getRecordData() {
        let params = {
          id: this.dataId,
          operationType: 'LPFXLB',
          pageNum: this.recordPager.pageNum,
          pageSize: this.recordPager.pageSize
        };
        this.$axios({
          url: this.$api.analysis.exportRecordList,
          data: params,
          beforeSend: () => {
            this.recordLoading = true;
          },
          complete: () => {
            this.recordLoading = false;
          }
        }).then(data => {
          this.recordDataList = data.list || [];
          this.recordPager.total = data.total || 0;
        });
      },
      /**
       * 导出
       */
      exportData() {
        let data = Object.assign({}, this.formData, {
          id: this.dataId
        });
        this.$axios({
          url: this.$api.analysis.exportBuildingInfoStaticticsUser,
          data,
          loading: true
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '操作成功，请稍后查看导出记录'
          });
        });
      },
      /**
       * 查看
       */
      showDetail(row, type) {
        this.drawerFlag = type;
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 切换页码或每条页数
       */
      changeRecordPage(pager) {
        this.recordPager.pageNum = pager.page;
        this.recordPager.pageSize = pager.pageSize;
        this.getRecordData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>